import {ERoute} from "apps/sliderary/configs/ERoute";
import {
  EColorScheme,
  EIconNames,
  ELocalStorageKeys,
  EPermissions,
  EProfileMenuItems,
  EHrefTarget,
} from "./enums";
import {type IServerUserSettings} from "../../types/common";
import {type Components} from "types/client";

export const PROFILE_MENU_PATH_DATA_MAP: Record<
  EProfileMenuItems,
  {
    text: string;
    iconName: EIconNames;
    linkTo?: ERoute | string;
    target?: EHrefTarget;
    permission: keyof Components.Schemas.Permissions | false;
    isShown: boolean;
  }
> = {
  [EProfileMenuItems.PROFILE]: {
    text: "Профиль",
    iconName: EIconNames.PROFILE,
    linkTo: ERoute.PROFILE,
    permission: false,
    isShown: true,
  },
  [EProfileMenuItems.UPLOADED]: {
    text: "Загрузки",
    iconName: EIconNames.UPLOAD,
    linkTo: ERoute.UPLOADED,
    permission: "canUpload",
    isShown: true,
  },
  [EProfileMenuItems.ADMINISTRATION]: {
    text: "Администрирование",
    iconName: EIconNames.GEAR,
    linkTo: ERoute.SERVICE_WIP,
    permission: "canManageContent",
    isShown: FEATURE_FLAGS.showAdministration,
  },
  [EProfileMenuItems.POLL]: {
    text: "Пройти опрос",
    iconName: EIconNames.ANNOUNCEMENT,
    linkTo: ARGO_ENV.EXTERNAL_LINK_POLL,
    permission: false,
    target: EHrefTarget.BLANK,
    isShown: true,
  },
};

export const PERMISSION_FIELD_MAP = {
  canUpload: EPermissions.UPLOAD,
  filterReviewed: EPermissions.REVIEWED_FILTER,
  canViewUserRating: EPermissions.VIEW_USER_RATING,
  canDeletePresentation: EPermissions.DELETE_PRESENTATION,
  canExport: EPermissions.EXPORT,
  canManageContent: EPermissions.CONTENT_ADMINISTRATION,
};

export const KEYS_TO_SAVE_IN_STORAGE = [ELocalStorageKeys.META_DATA_SHOWN];

export const serverUserSettings: IServerUserSettings = {
  theme: "THEME",
};

export const DEFAULT_COLOR_SCHEME = EColorScheme.LIGHT;

export const EXPIRES_TO_DELETE = "Thu, 01 Jan 1970 00:00:00 GMT";
