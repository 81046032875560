export enum EIconNames {
  ARROW_RIGHT = "arrowRight",
  ARROW_UP = "arrowUp",
  CHECKBOX = "checkbox",
  FLAG = "flag",
  FLAG_FILLED = "flagFilled",
  BIN = "bin",
  CROSS = "cross",
  CHAIN = "chain",
  EXCLAMATION = "exclamation",
  SPINNER = "loader",
  UPLOAD = "upload",
  LOGOUT = "logout",
  PROFILE = "profile",
  UPLOAD_SIGN = "upload_sign",
  PPTX = "pptx",
  STAR = "star",
  LOCK = "lock",
  STAR_OFF = "star_off",
  ARROW_LEFT = "arrowLeft",
  INFO_CIRCLE = "info_circle",
  EDIT = "edit",
  TRUE_BADGE = "true_badge",
  FALSE_BADGE = "false_badge",
  COMMON_PLUS = "common_plus",
  COMMON_MINUS = "common_minus",
  SEARCH = "search",
  KEY = "key",
  ANNOUNCEMENT = "announcement",
  GEAR = "gear",
}

export enum ESizes {
  XL = "xl",
  L = "l",
  M = "m",
  S = "s",
  XS = "xs",
}

export enum EButtonTypes {
  GHOST = "ghost",
  BORDER = "border",
  FILL = "fill",
}

export enum EButtonStyles {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  LABEL = "label",
}

export enum EProfileMenuItems {
  PROFILE = "profile",
  UPLOADED = "uploaded",
  POLL = "poll",
  ADMINISTRATION = "administration",
}

export enum EPermissions {
  UPLOAD,
  VIEW_USER_RATING,
  REVIEWED_FILTER,
  DELETE_PRESENTATION,
  EXPORT,
  CONTENT_ADMINISTRATION,
}

export enum ECommonEvents {
  RESET_PERMISSIONS = "reset_permissions",
}

export enum EMetaDataType {
  BOOLEAN = "upw.mbool",
  STRING = "upw.mstr",
  DATE = "upw.mdate",
  ENUM = "upw.menum",
  FLOAT = "upw.mfloat",
  INTEGER = "upw.mint",
}

export enum EMetaDataTypeEditable {
  BOOLEAN = "pw.mbool",
  STRING = "pw.mstr",
  DATE = "pw.mdate",
  ENUM = "pw.menum",
  FLOAT = "pw.mfloat",
  INTEGER = "pw.mint",
}

export enum EMetaDataPhysicalType {
  STRING = 1,
  INTEGER = 2,
  FLOAT = 3,
  ENUM = 4,
  DATE = 5,
  BOOLEAN = 6,
}

export enum EElementColors {
  DEFAULT = "default",
  SUCCESS = "success",
  WARNING = "warning",
  DANGER = "danger",
}

export enum EMainGroupType {
  CATEGORY = 1,
  PRESENTATION = 2,
}

export enum EZoomRange {
  ZOOM_MIN = 1,
  ZOOM_MAX = 3,
}

export enum EUserSettings {
  THEME = "theme",
  ZOOM_MAIN = "zoomMain",
  ZOOM_FAVOURITE = "zoomFavourite",
  META_DATA_SHOWN = "metaDataShown",
}

export enum ELocalStorageKeys {
  SELECTABLE_ITEMS = "selectable",
  SLIDE_ID_TO_ID_MAP = "slideIdToIdMap",
  CATEGORY_ID_TO_ID_MAP = "categoryIdToIdMap",
  ACCESS_TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
  ZOOM_MAIN = "zoomMain",
  ZOOM_FAVOURITE = "zoomFavourite",
  META_DATA_SHOWN = "metaDataShown",
  SIDEBAR_WIDTH = "sidebarWidth",
  FILTER_BY_PRESENTATIONS = "filterByPresentations",
  FILTER_BY_REVIEWED = "filterByReviewed",
  FILTER_BY_FAVOURITES = "filterByFavourites",
  FILTER_BY_RATED = "filterByRated",
  THEME = "theme",
}

export enum EColorScheme {
  DARK = "dark",
  LIGHT = "light",
}

export enum ETheme {
  LIGHT_CORPORATE_THEME = "light-corporate-theme",
  DARK_CORPORATE_THEME = "dark-corporate-theme",
  EDUCATIONAL_THEME = "educational-theme",
}

export enum ECssCustomProperties {
  SIDEBAR_WIDTH = "--sidebar-width",
}

export enum ECookieSSO {
  SSO_COMPLETE = "Slidoteka.SSO.CanComplete",
  SSO_ERROR = "Slidoteka.SSO.Error",
}

export enum EHrefTarget {
  BLANK = "_blank",
  SELF = "_self",
  TOP = "_top",
  PARENT = "_parent",
}

export enum EStyleTypeCheckbox {
  SLIDE = "slide",
  PRESENTATION = "presentation",
  SLIDEVIEW = "slideView",
}

export enum ECheckTypeCheckbox {
  CHECK = "check",
  FLAG = "flag",
}

export enum EFormTypeCheckbox {
  ROUND = "round",
  SQUARE = "square",
}
