import {isRouteErrorResponse} from "react-router-dom";
import {isAxiosError, isCancel} from "axios";

interface ICombinedError {
  status: number | null;
  statusText: string | null;
  data: string | null;
  error?: Error | null;
}

export function getCombinedError(error: unknown) {
  let result: ICombinedError = {
    status: null,
    statusText: null,
    data: null,
    error: null,
  };

  if (isRouteErrorResponse(error)) {
    const {internal, data, ...rest} = error;
    result = {...rest, data: String(data)};
  } else if (isAxiosError(error)) {
    const {status, message} = error;
    result = {
      ...result,
      status: status ?? null,
      statusText: isCancel(error) ? "Запрос отменен" : null,
      data: message,
    };
  } else if (error instanceof Error) {
    const {message} = error;
    result = {...result, data: message};
  } else {
    result = {...result, data: String(error)};
  }

  return result;
}
